body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}



/**
 * Spacing classes
 * Should be used to modify the default spacing between objects (not between nodes of the same object)
 * Please use judiciously. You want to be using defaults most of the time, these are exceptions!
 * <type><location><size>
 */
/* ====== Default spacing ====== */

/* spacing helpers
p,m = padding,margin
a,t,r,b,l,h,v = all,top,right,bottom,left,horizontal,vertical
s,m,l,n = small(5px),medium(10px),large(20px),none(0px)
*/


.ptn,.pvn,.pan{padding-top:0px !important}
.pts,.pvs,.pas{padding-top:5px !important}
.ptm,.pvm,.pam{padding-top:10px !important}
.ptl,.pvl,.pal{padding-top:20px !important}
.prn,.phn,.pan{padding-right:0px !important}
.prs,.phs,.pas{padding-right:5px !important}
.prm,.phm,.pam{padding-right:10px !important}
.prl,.phl,.pal{padding-right:20px !important}
.pbn,.pvn,.pan{padding-bottom:0px !important}
.pbs,.pvs,.pas{padding-bottom:5px !important}
.pbm,.pvm,.pam{padding-bottom:10px !important}
.pbl,.pvl,.pal{padding-bottom:20px !important}
.pln,.phn,.pan{padding-left:0px !important}
.pls,.phs,.pas{padding-left:5px !important}
.plm,.phm,.pam{padding-left:10px !important}
.pll,.phl,.pal{padding-left:20px !important}
.mtn,.mvn,.man{margin-top:0px !important}
.mts,.mvs,.mas{margin-top:5px !important}
.mtm,.mvm,.mam{margin-top:10px !important}
.mtl,.mvl,.mal{margin-top:20px !important}
.mtxl,.mvxl,.maxl{margin-top:60px !important}
.mtxxl,.mvxxl,.maxxl{margin-top:140px !important}
.mrn,.mhn,.man{margin-right:0px !important}
.mrs,.mhs,.mas{margin-right:5px !important}
.mrm,.mhm,.mam{margin-right:10px !important}
.mrl,.mhl,.mal{margin-right:20px !important}
.mrxl,.mhxl,.maxl{margin-right:60px !important}
.mrxxl,.mhxxl,.maxxl{margin-right:140px !important}
.mbn,.mvn,.man{margin-bottom:0px !important}
.mbs,.mvs,.mas{margin-bottom:5px !important}
.mbm,.mvm,.mam{margin-bottom:10px !important}
.mbl,.mvl,.mal{margin-bottom:20px !important}
.mbxl,.mvxl,.maxl{margin-bottom:60px !important}
.mbxxl,.mvxxl,.maxxl{margin-bottom:140px !important}
.mln,.mhn,.man{margin-left:0px !important}
.mls,.mhs,.mas{margin-left:5px !important}
.mlm,.mhm,.mam{margin-left:10px !important}
.mll,.mhl,.mal{margin-left:20px !important}
.mlxl,.mhxl,.maxl{margin-left:60px !important}
.mlxxl,.mhxxl,.maxxl{margin-left:140px !important}

.mtnegm {margin-top: -10px !important}
.mbnegm {margin-bottom: -10px !important}
.mlnegm {margin-left:-10px !important}
.mlnegs {margin-left:-5px !important}
.mrnegm{margin-right:-10px !important}
.mrnegs{margin-right:-5px !important}